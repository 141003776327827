import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'
import { timeAgo, formatDate } from '@/utils/dates'
import { downloadFile } from '@/utils/download'
import { capitalize } from 'lodash-es'
import { setMiniStepperStatus } from '../general-settings/connections'

export const entityColors = {
  Account: 'ap-dark-blue',
  Lead: 'ap-green',
  Contact: 'ap-light-blue',
  account: 'ap-dark-blue',
  lead: 'ap-green',
  contact: 'ap-light-blue',
  Staging_Lead: 'brown',
  All: 'ap-red',
}

export const RULE_TYPES = {
  agent: 'Agent',
  director: 'Director',
  multiMap: 'MultiMap',
  segmentation: 'Filter',
  matchCriteria: 'MatchCriteria',
  assignment: 'Assignment',
  primary: 'SelectPrimary',
  rollUp: 'RollUp',
  write: 'Write',
  presentation: 'Presentation',
  search: 'Search',
}

const RULE_RESPONSE_PATHS = {
  id: 'id',
  name: 'name',
  type: 'type',
  description: 'description',
  entities: 'entities',
  status: 'status',
  modifiedAt: 'modified',
  agentType: 'agent_type',
}

function ruleResponseExtraMapping(mappedItem) {
  return {
    ...mappedItem,
    descriptionTruncated:
      mappedItem.description?.length < 70
        ? mappedItem.description
        : mappedItem.description?.substr(0, 70) + '...',
    isStatusActive: mappedItem.status === 'active',
    isStatusInactive: mappedItem.status === 'inactive',
    modifiedAtFormatted: formatDate(
      mappedItem.modifiedAt,
      'dd LLL yyyy | HH:mm'
    ),
    modifiedAtTimeAgo: timeAgo(mappedItem.modifiedAt),
  }
}

const RULES_REQUEST_PATHS = {
  per_page: 'perPage',
  page: 'page',
  order_by_field: 'orderByField',
  order: 'order_by',
  type: 'type',
  subType: 'sub_type',
  agent_id: 'agentId',
}

export const getSearchMetadata = () => {
  return http.$get('/search/meta').then((response) => {
    return response
  })
}

export const getRule = (id) => {
  return http.$get('/rule/rules/' + id).then((rule) => {
    return mapResponseRule(rule)
  })
}

export const getRules = (payload) => {
  const params = mapToObject(payload, RULES_REQUEST_PATHS)

  return http.$get('/rule/rules', { params }).then((response) => {
    const rules = []

    response.data.forEach((rule) => {
      if (rule) {
        rules.push(mapResponseRule(rule))
      }
    })

    return rules
  })
}

function mapResponseRule(rule) {
  switch (rule.type) {
    case RULE_TYPES.agent:
      return mapResponseAgentRule(rule)
    case RULE_TYPES.director:
      return mapResponseDirectorRule(rule)
    case RULE_TYPES.segmentation:
      return mapResponseFilterRule(rule)
    case RULE_TYPES.multiMap:
      return mapResponseMultiMapRule(rule)
    case RULE_TYPES.presentation:
      return mapResponsePresentationRule(rule)
    case RULE_TYPES.matchCriteria:
      return mapResponseMatchingRule(rule)
    case RULE_TYPES.assignment:
      return mapResponseAssignmentRule(rule)
    case RULE_TYPES.primary:
      return mapResponsePrimaryRule(rule)
    case RULE_TYPES.rollUp:
      return mapResponseRollUpRule(rule)
    case RULE_TYPES.write:
      return mapResponseWriteRule(rule)
    case RULE_TYPES.search:
      return mapResponseSearchRule(rule)
    default:
      return null
  }
}

function mapResponseAgentRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    rules: rule.directors.map((el) => el.director_id),
  }
}

function mapResponseDirectorRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    objects: rule.objects,
    isDraft: Object.keys(rule.rules).length < 8,
    rules: rule.rules.reduce((acc, el) => {
      return {
        ...acc,
        [el.rule_type]: { id: el.rule_id },
      }
    }, {}),
  }
}

export async function activateDirectorRule(
  id,
  { confirmed = false, setMiniStepper = false }
) {
  return http
    .$post(`/rule/rules/director/${id}/activate`, { confirmed })
    .then(async (response) => {
      if (response.needs_confirmation) {
        return {
          needsConfirmation: true,
          affectedDuplicates: response.affected_duplicates,
        }
      } else {
        if (setMiniStepper) {
          await setMiniStepperStatus({ directorsVisited: true })
        }

        const rules = []

        response.data.forEach((rule) => {
          if (rule) {
            rules.push(mapResponseRule(rule))
          }
        })

        return rules
      }
    })
}

export async function deactivateDirectorRule(id, confirmed = false) {
  return http
    .$post(`/rule/rules/director/${id}/deactivate`, { confirmed })
    .then(async (response) => {
      if (response.needs_confirmation) {
        return {
          needsConfirmation: true,
          affectedDuplicates: response.affected_duplicates,
        }
      } else {
        const rules = []

        response.data.forEach((rule) => {
          if (rule) {
            rules.push(mapResponseRule(rule))
          }
        })

        return rules
      }
    })
}

function mapResponseFilterRule(response) {
  const segmentations = []
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  for (const prop in rule) {
    const entity = rule[prop]

    segmentations.push({
      entityName: prop,
      selectEntireDatabase: entity.config[0].config.conditions.length === 0,
      conditions: entity.config[0].config.conditions.map((condition) => {
        return {
          operator: condition.algo,
          field: condition.field,
          value: condition.value,
        }
      }),
    })
  }

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    segmentations,
  }
}

function mapResponseMatchingRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  const ruleConfig =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  const configs = ruleConfig.config.map((config) => {
    const conditions = config.config.conditions.map((condition) => {
      const values = {
        ...condition,
      }

      if (values.fuzzy_threshold) {
        values.fuzzy_threshold = (1.0 - values.fuzzy_threshold) * 100
      }

      return values
    })

    return {
      conditions,
      confidence: config.config.confidence,
    }
  })

  return {
    ...rule,
    configs,
  }
}

function mapResponseAssignmentRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  const exceptions = response.rule.exceptions.map((exception) => {
    return {
      type: 'exception',
      action: exception.actions,
      users: exception.user_ids,
      conditions: [],
    }
  })
  return {
    ...rule,
    assignments: [
      ...response.rule.rules.map((rule) => {
        const [category, ...conditions] = rule.operators

        return {
          type: category?.[2] ? 'confidence_score' : 'default',
          category: category?.[2],
          assignToUserId: rule.assign_to.user_id,
          assignToUserType: rule.assign_to.user_type,
          action: rule.actions,
          conditions:
            conditions?.map((condition) => {
              return {
                operator: condition[0],
                value: condition[1],
              }
            }) || [],
          users: [],
        }
      }),
      ...exceptions,
    ],
  }
}

function mapResponsePrimaryRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    criterias: response.rule.rules.reduce((e, entity) => {
      return {
        ...e,
        [entity.entity_name]: entity.conditions.map((criteria) => {
          return criteria.operators.reduce((acc, condition, index) => {
            if (index === 0) {
              return acc
            }

            let operator = condition[0]
            let value = condition[2]
            if (['is_before', 'is_after', 'is_exact'].includes(operator)) {
              value = condition[2].substr(0, 10)
            } else if (operator === 'is_between') {
              value = [
                condition[2][0].substr(0, 10),
                condition[2][1].substr(0, 10),
              ]
            }

            return [
              ...acc,
              {
                field: condition[1],
                operator,
                value,
              },
            ]
          }, [])
        }),
      }
    }, {}),
  }
}

function mapResponseRollUpRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    conditions: response.rule.rules.map((el) => {
      const isPrimaryCondition = el.when?.record?.find(
        (condition) => condition[0] === 'IsPrimary'
      )

      return {
        finalRecordEntity: el.final_record_entity,
        finalRecordEntityColor: entityColors[el.final_record_entity],
        lookUpEntities: el.look_up_entities,
        whenByColumn:
          el.when?.column?.map((column) => {
            return {
              field: column[1],
              operator: column[0],
              value: column[2],
            }
          }) || [],
        whenByRecord: {
          isPrimary: isPrimaryCondition?.[1] || false,
          conditions:
            el.when?.record?.reduce((acc, record) => {
              if (record[0] !== 'IsPrimary') {
                return [
                  ...acc,
                  {
                    field: record[1],
                    operator: record[0],
                    value: record[2],
                  },
                ]
              }
              return acc
            }, []) || [],
        },
        fieldsToRollUp: el.then.map((field) => {
          return {
            name: field.field_name,
            operator: field.operator,
            value:
              field.order_by_field ||
              field.order_by_data ||
              field.value ||
              field.from_field_name,
            overridePrimary: field.override_primary,
          }
        }),
      }
    }),
  }
}

function mapResponseWriteRule(response) {
  const rule = mapToObject(response, RULE_RESPONSE_PATHS, {
    extraMapping: ruleResponseExtraMapping,
  })

  return {
    ...rule,
    conditions: response.rule.entities.map((el) => {
      let extraOptions = {}

      if (el.entity_name.toLowerCase() === 'lead') {
        const convertConfigs = el.operators.find(
          (operator) => operator.operator_name === 'convert'
        )

        extraOptions = {
          convert: !!convertConfigs,
        }

        if (convertConfigs) {
          let conditions = []

          if (convertConfigs?.when) {
            conditions = convertConfigs.when.map((conditions) => {
              return conditions.conditions.map((condition) => {
                return {
                  field: condition[1],
                  operator: condition[0],
                  value: condition[2],
                }
              })
            })
          }

          extraOptions = {
            ...extraOptions,
            createOpportunity: convertConfigs.arguments.create_opportunity,
            convertedStatus: convertConfigs.arguments.converted_status,
            conditions,
          }
        }
      }

      const mergeConfigs = el.operators.find(
        (operator) => operator.operator_name === 'merge'
      )

      return {
        entity: el.entity_name,
        automerge: !!mergeConfigs.automerge,
        ...extraOptions,
      }
    }),
  }
}

export function mapResponseMultiMapRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    objects: rule.objects?.map((object) => {
      return {
        name: object,
        label: capitalize(object),
      }
    }),
    groups: rule.object_fields?.map((el) => {
      const group = {}
      rule.objects.forEach((object, index) => {
        group[object] =
          el.fields[index] === null ? [] : [{ name: el.fields[index] }]
      })
      return {
        groupName: el.name,
        group,
      }
    }),
    isBeingUsed: response.directors_count > 0,
    crawlerId: rule.crawlerId,
  }
}

function mapResponsePresentationRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  const fieldsPerRole = Object.keys(rule)
    .filter((el) => el !== 'multi_map_id')
    .map((key) => {
      return {
        id: key,
        fields: rule[key].column_settings.map((field) => {
          return {
            name: field.name,
            label: field.label,
            isDisplayed: field.is_displayed,
            isEditable: field.is_editable,
            isConflictable: field.is_conflictable,
          }
        }),
      }
    })
  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    multiMapId: rule.multi_map_id,
    isBeingUsed: response.directors_count > 0,
    fieldsPerRole,
  }
}

function mapRequestAgentRule(agentRule, directorRules) {
  return {
    type: agentRule.type,
    rule: {
      directors: directorRules.map((el) => {
        return {
          director_id: el.id,
        }
      }),
    },
  }
}

export const updateAgentRule = (agentRule, directorRules) => {
  return http
    .$post(
      `/rule/rules/${agentRule.id}`,
      mapRequestAgentRule(agentRule, directorRules)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

export const createDirectorRule = (payload) => {
  return http
    .$post('/rule/rules', mapRequestDirectorRule(payload))
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateDirectorRule = (payload) => {
  return http
    .$post(`/rule/rules/${payload.id}`, mapRequestDirectorRule(payload))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestDirectorRule(directorRule) {
  return {
    id: directorRule.id,
    agent_id: directorRule.agentId,
    name: directorRule.name,
    description: directorRule.description,
    status: directorRule.status,
    type: directorRule.type,
    entities: directorRule.entities,
    rule: {
      objects: directorRule.objects,
      rules: Object.entries(directorRule.rules).map(([ruleType, rule]) => {
        return {
          rule_id: rule.id,
          rule_type: ruleType,
        }
      }),
    },
  }
}

export const createSearchRule = (directorRuleId, rule, fields, actions) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestSearchRule(rule, fields, actions)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateSearchRule = (rule, fields, actions) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestSearchRule(rule, fields, actions)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestSearchRule(rule, fields, actions) {
  const actionsRule = []

  if (actions.isWarnActionEnabled) {
    actionsRule.push({
      action: 'warn',
      when: [{ threshold: { gt: actions.warnThreshold } }],
    })
  }

  if (actions.isBlockActionEnabled) {
    actionsRule.push({
      action: 'block',
      when: [{ threshold: { gt: actions.blockThreshold } }],
    })
  }

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: {
      entities: [
        {
          entity_name: 'Account',
          search_fields: fields.map((field) => {
            return { name: field }
          }),
          actions: actionsRule,
        },
      ],
    },
  }
}

function mapResponseSearchRule(response) {
  const rule =
    typeof response.rule === 'string'
      ? JSON.parse(response.rule)
      : response.rule

  const warnAction = rule[0].actions.find((action) => action.action === 'warn')
  const blockAction = rule[0].actions.find(
    (action) => action.action === 'block'
  )

  const actions = {
    isWarnActionEnabled: !!warnAction,
    isBlockActionEnabled: !!blockAction,

    warnThreshold: warnAction ? warnAction.when[0].threshold.gt : null,
    blockThreshold: blockAction ? blockAction.when[0].threshold.gt : null,
  }

  return {
    ...mapToObject(response, RULE_RESPONSE_PATHS, {
      extraMapping: ruleResponseExtraMapping,
    }),
    fields: rule[0].search_fields.map((field) => field.name),
    actions,
  }
}

export const createSegmentationRule = (directorRuleId, rule, segmentations) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestSegmentationRule(rule, segmentations)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateSegmentationRule = (rule, segmentations) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestSegmentationRule(rule, segmentations)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestSegmentationRule(rule, segmentations) {
  const ruleConfig = {}

  segmentations.forEach((segmentation) => {
    ruleConfig[segmentation.entityName] = {
      entity_name: segmentation.entityName,
      class: 'OrClause',
      config: [
        {
          class: 'AndClause',
          config: {
            conditions: segmentation.conditions.map((condition) => {
              return {
                algo: condition.operator,
                field: condition.field,
                value: condition.value,
                case_sensitive: false,
              }
            }),
          },
        },
      ],
    }
  })

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createMatchCriteriaRule = (
  directorRuleId,
  rule,
  criterias,
  crossObject
) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestMatchCriteriaRule(rule, criterias, crossObject)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateMatchCriteriaRule = (rule, criterias, crossObject) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestMatchCriteriaRule(rule, criterias, crossObject)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestMatchCriteriaRule(rule, criterias, crossObject) {
  const ruleConfig = {
    class: 'OrClause',
    config: [
      ...criterias.map((criteria) => ({
        class: 'AndClause',
        config: {
          conditions: criteria.conditions.map((condition) => {
            if (condition.fuzzy_threshold) {
              return {
                ...condition,
                fuzzy_threshold: 1.0 - condition.fuzzy_threshold / 100,
              }
            }

            return condition
          }),
          confidence: criteria.confidence,
        },
      })),
    ],
    cross_object: crossObject,
  }

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createAssignmentRule = (directorRuleId, rule, assignments) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestAssignmentRule(rule, assignments)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateAssignmentRule = (rule, assignments) => {
  return http
    .$post(
      `/rule/rules/${rule.id}`,
      mapRequestAssignmentRule(rule, assignments)
    )
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestAssignmentRule(rule, assignments) {
  const ruleConfig = {
    rules: [
      ...assignments
        .filter((el) => el.type !== 'exception')
        .map((el) => {
          const rule = {
            operators: [],
            assign_to: {},
            actions: el.action,
          }

          if (el.type === 'confidence_score') {
            rule.operators = [['=', 'mcs_category', el.category]]
          }

          rule.operators = [
            ...rule.operators,
            ...el.conditions.map((condition) => [
              condition.operator,
              condition.value,
            ]),
          ]

          if (el.assignToUserId) {
            rule.assign_to.user_id = el.assignToUserId
          } else if (el.assignToUserType) {
            rule.assign_to.user_type = el.assignToUserType
          }

          return rule
        }),
    ],
    exceptions: [
      ...assignments
        .filter((el) => el.type === 'exception')
        .map((el) => {
          return {
            actions: el.action,
            user_ids: el.users,
          }
        }),
    ],
  }

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createPrimaryRule = (directorRuleId, rule, conditions) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestPrimaryRule(rule, conditions)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updatePrimaryRule = (rule, conditions) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestPrimaryRule(rule, conditions))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestPrimaryRule(rule, criteriasByEntity) {
  const ruleConfig = {
    rules: [],
  }

  Object.keys(criteriasByEntity).forEach((entityName) => {
    const criterias = []

    criteriasByEntity[entityName].forEach((conditions) => {
      criterias.push({
        operators: [
          ['column_has_only', 'source', [entityName]],
          ...conditions.map((condition) => {
            let value = condition.value
            if (
              ['is_before', 'is_after', 'is_exact'].includes(condition.operator)
            ) {
              value = condition.value + 'T00:00:00Z'
            } else if (condition.operator === 'is_between') {
              value = [value[0] + 'T00:00:00Z', value[1] + 'T00:00:00Z']
            }

            return [condition.operator, condition.field, value]
          }),
        ],
        action: ['select_primary', entityName],
      })
    })

    ruleConfig.rules.push({
      entity_name: entityName,
      conditions: criterias,
    })
  })

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createRollUpRule = (directorRuleId, rule, rollUp) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestRollUpRule(rule, rollUp)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateRollUpRule = (rule, rollUp) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestRollUpRule(rule, rollUp))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestRollUpRule(rule, items) {
  const ruleConfig = {
    rules: [],
  }

  items.forEach((item) => {
    let whenCondition = {}
    let whenRecordConditions = []
    let whenColumnConditions = []

    if (item.whenByRecord?.conditions?.length > 0) {
      whenRecordConditions = [
        ['IsPrimary', item.whenByRecord?.isPrimary],
        ...item.whenByRecord.conditions.map((el) => [
          el.operator,
          el.field,
          el.value,
        ]),
      ]

      whenCondition.record = whenRecordConditions
    }

    if (item.whenByColumn?.length > 0) {
      whenColumnConditions = item.whenByColumn?.map((el) => [
        el.operator,
        el.field,
        el.value,
      ])

      whenCondition.column = whenColumnConditions
    }

    const rule = {
      final_record_entity: item.finalRecordEntity,
      look_up_entities: item.lookUpEntities,
      when: whenCondition,
      then: item.fieldsToRollUp.map((el) => {
        var field = {
          field_name: el.name,
          operator: el.operator,
          override_primary: el.overridePrimary,
        }

        if (['GetNewestValue', 'GetOldestValue'].includes(el.operator)) {
          field = {
            ...field,
            order_by_field: el.value,
          }
        } else if (el.operator === 'GetHighestOrder') {
          field = {
            ...field,
            order_by_data: el.value,
          }
        } else if (el.operator === 'SetValueFromRecord') {
          field = {
            ...field,
            from_field_name: el.value,
          }
        } else {
          field = {
            ...field,
            value: el.value,
          }
        }

        return field
      }),
    }

    ruleConfig.rules.push(rule)
  })

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const createWriteRule = (directorRuleId, rule, conditions) => {
  return http
    .$post(
      `/rule/rules/director/${directorRuleId}`,
      mapRequestWriteRule(rule, conditions)
    )
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updateWriteRule = (rule, conditions) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestWriteRule(rule, conditions))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

function mapRequestWriteRule(rule, conditions) {
  const ruleConfig = {
    entities: conditions.map((condition) => {
      const actions = [
        {
          operator_name: 'update',
        },
        {
          operator_name: 'merge',
          automerge: !!condition.automerge,
        },
      ]

      if (condition.entity.toLowerCase() === 'lead') {
        if (condition.convert) {
          actions.push({
            operator_name: 'convert',
            arguments: {
              converted_status: condition.convertedStatus,
              create_opportunity: condition.createOpportunity,
            },
            when: condition.conditions.map((conditions) => {
              return {
                conditions: conditions.map((condition) => [
                  condition.operator,
                  condition.field,
                  condition.value,
                ]),
              }
            }),
          })
        }
      }

      return {
        entity_name: condition.entity,
        operators: actions,
      }
    }),
  }

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    status: rule.status === undefined ? 'active' : rule.status,
    type: rule.type,
    entities: rule.entities || 'Account',
    rule: ruleConfig,
  }
}

export const deleteRule = (ruleId) => http.$delete(`/rule/rules/${ruleId}`)

const FIELDS_RESPONSE_PATHS = {
  name: 'name',
  label: 'label',
  operators: 'operators',
}

const OPERATORS_RESPONSE_PATHS = {
  label: 'label',
  operator: 'operator',
  values: 'values',
}

export const getRuleEntityMetadata = ({ ruleType, multiMapId, entities }) => {
  return http
    .$post(`/rule/settings/entities/${ruleType}`, {
      entities,
      multi_map_id: multiMapId,
      dd_metadata: false,
    })
    .then((response) => {
      return mapRuleMetadata(ruleType, response)
    })
}

function mapRuleMetadata(ruleType, metadata) {
  switch (ruleType) {
    case RULE_TYPES.segmentation:
      return mapMetadataFilterRule(metadata)
    case RULE_TYPES.matchCriteria:
      return mapMetadataMatchingRule(metadata)
    case RULE_TYPES.assignment:
      return mapMetadataAssignmentRule(metadata)
    case RULE_TYPES.primary:
      return mapMetadataPrimaryRule(metadata)
    case RULE_TYPES.rollUp:
      return mapMetadataRollUpRule(metadata)
    case RULE_TYPES.write:
      return mapMetadataWriteRule(metadata)
    case RULE_TYPES.presentation:
      return mapMetadataPresentationRule(metadata)
    default:
      return null
  }
}

function mapMetadataFilterRule(response) {
  const entitiesMetadata = {}

  response.forEach((object) => {
    entitiesMetadata[object.name] = {
      label: object.label,
      fields: mapToArray(object.fields, FIELDS_RESPONSE_PATHS, {
        extraMapping: (mappedItem) => {
          return {
            ...mappedItem,
            operators: mapToArray(
              mappedItem.operators,
              OPERATORS_RESPONSE_PATHS
            ),
          }
        },
      }),
    }
  })

  return entitiesMetadata
}

function mapMetadataMatchingRule(response) {
  const fields = response.fields

  const operators = response.operators.map((operator) => {
    return {
      isEnabled: operator.enabled,
      label: operator.label,
      name: operator.name,
      options: operator.options.map((option) => {
        if (option.name === 'fuzzy_threshold') {
          return {
            ...option,
            min: Math.round((1 - option.max) * 100),
            max: (1 - option.min) * 100,
            default: (1 - option.default) * 100,
          }
        }

        return option
      }),
    }
  })

  return {
    fields,
    operators,
  }
}

function mapMetadataAssignmentRule(response) {
  const users = response.assign_to
    .filter((user) => user.user_name)
    .map((user) => {
      return {
        id: user.user_id,
        username: user.user_name,
        roleId: user.role_id,
        roleName: user.role_name,
      }
    })

  const userTypes = response.assign_to
    .filter((user) => user.user_type)
    .map((user) => {
      return {
        type: user.type,
        label: user.label,
      }
    })

  const operators = response.operators.map((operator) => {
    return {
      name: operator.name,
      label: operator.label,
      values: operator.operands.map((value) => {
        return {
          name: value.name,
          label: value.label,
        }
      }),
    }
  })

  return {
    users,
    userTypes,
    operators,
  }
}

function mapMetadataPrimaryRule(response) {
  const entitiesMetadata = {}

  response.forEach((object) => {
    entitiesMetadata[object.name] = {
      label: object.label,
      fields: object.fields.filter((el) => el.name !== 'source'),
    }
  })

  return entitiesMetadata
}

function mapMetadataRollUpRule(response) {
  const entities = response.roll_up_objects.map((el) => {
    return {
      name: el.name,
      label: el.label,
    }
  })

  const rollUpFieldsPerEntity = {}
  const rollUpFieldsPerEntityObject = {}

  response.roll_up_objects.forEach((entity) => {
    rollUpFieldsPerEntity[entity.name] = entity.fields.map((field) => {
      const operatorsObj = {}
      const operators = []

      field.roll_up_operators.forEach((operator) => {
        const valuesObj = {}
        if (
          Array.isArray(operator.values) &&
          typeof operator.values[0] === 'object'
        ) {
          operator.values.forEach((field) => {
            valuesObj[field.name] = {
              name: field.name,
              label: field.label,
              type: field.type,
            }
          })
        }

        const operatorMapped = {
          label: operator.label,
          name: operator.name,
          type: operator.type,
          multiple: operator.multiple,
          values: operator.values,
          valuesObj,
          valuesLabel: operator.values_label,
        }

        operators.push(operatorMapped)
        operatorsObj[operator.name] = operatorMapped
      })

      rollUpFieldsPerEntityObject[entity.name] = {
        ...rollUpFieldsPerEntityObject[entity.name],
        [field.name]: {
          label: field.label,
          name: field.name,
          type: field.type,
          operatorsObj,
          operators,
        },
      }

      return {
        label: field.label,
        name: field.name,
        type: field.type,
        operators: field.roll_up_operators.map((op) => {
          return {
            label: op.label,
            name: op.name,
            type: op.type,
            multiple: op.multiple,
            values: op.values,
            valuesLabel: op.values_label,
          }
        }),
      }
    })
  })

  let whenColumn = {}
  response.when_to_apply?.column.forEach((field) => {
    let operatorsObj = {}
    const operators = []

    field.operators.forEach((operator) => {
      const operatorMapped = {
        label: operator.label,
        name: operator.name,
        type: operator.type,
        multiple: operator.multiple,
        values: operator.values,
        valuesLabel: operator.values_label,
      }

      operators.push(operatorMapped)
      operatorsObj[operator.name] = operatorMapped
    })

    whenColumn[field.field_name] = {
      ...whenColumn,
      fieldLabel: field.field_label,
      fieldName: field.field_name,
      fieldType: field.field_type,
      operatorsObj,
      operators,
    }
  })

  let whenRecord = {}
  response.when_to_apply?.record.forEach((field) => {
    let operatorsObj = {}
    const operators = []

    field.operators.forEach((operator) => {
      const operatorMapped = {
        label: operator.label,
        name: operator.name,
        type: operator.type,
        multiple: operator.multiple,
        values: operator.values,
        valuesLabel: operator.values_label,
      }

      operators.push(operatorMapped)
      operatorsObj[operator.name] = operatorMapped
    })

    whenRecord[field.field_name] = {
      fieldLabel: field.field_label,
      fieldName: field.field_name,
      fieldType: field.field_type,
      operatorsObj,
      operators,
    }
  })

  return {
    entities,
    rollUpFieldsPerEntity,
    rollUpFieldsPerEntityObject,
    whenToApplyObj: {
      column: whenColumn,
      record: whenRecord,
    },
    whenToApply: {
      column: response.when_to_apply?.column.map((field) => {
        return {
          fieldLabel: field.field_label,
          fieldName: field.field_name,
          fieldType: field.field_type,
          operators: field.operators.map((operator) => {
            return {
              label: operator.label,
              name: operator.name,
              type: operator.type,
              multiple: operator.multiple,
              values: operator.values,
              valuesLabel: operator.value_label,
            }
          }),
        }
      }),
      record: response.when_to_apply?.record.map((field) => {
        return {
          fieldLabel: field.field_label,
          fieldName: field.field_name,
          fieldType: field.field_type,
          operators: field.operators.map((operator) => {
            return {
              label: operator.label,
              name: operator.name,
              type: operator.type,
              multiple: operator.multiple,
              values: operator.values,
              valuesLabel: operator.value_label,
            }
          }),
        }
      }),
    },
  }
}

function mapMetadataWriteRule(response) {
  return {
    entities: response.objects.map((el) => {
      return {
        name: el.name,
        label: el.label,
      }
    }),
    convertedStatus:
      response.converted_status?.map((el) => {
        return {
          name: el.name,
          label: el.label,
        }
      }) || [],
    convertConditions:
      response.when?.map((el) => {
        return {
          name: el.name,
          label: el.label,
          type: el.type,
          operators: el.operators.map((operator) => {
            return {
              name: operator.name,
              label: operator.label,
            }
          }),
        }
      }) || [],
  }
}

function mapMetadataPresentationRule(response) {
  return response.map((field) => {
    return {
      name: field.name,
      label: field.label,
      isUpdatable: field.is_updateable,
    }
  })
}

export const createPresentationRule = (rule) => {
  return http
    .$post('/rule/rules', mapRequestPresentationRule(rule))
    .then((response) => {
      return mapResponseRule(response)
    })
}

export const updatePresentationRule = (rule) => {
  return http
    .$post(`/rule/rules/${rule.id}`, mapRequestPresentationRule(rule))
    .then((response) => {
      return mapResponseRule(response.data[0])
    })
}

export const mapRequestPresentationRule = (rule) => {
  const roles = {}

  rule.fieldsPerRole.forEach((role) => {
    roles[role.id] = {
      column_settings: role.fields.map((field) => {
        return {
          name: field.name,
          label: field.label,
          is_primary: field.name === 'sfid' || field.name === 'Id',
          is_displayed: field.isDisplayed,
          is_editable: field.isEditable,
          is_conflictable: field.isConflictable,
        }
      }),
    }
  })

  return {
    id: rule.id,
    name: rule.name,
    description: rule.description,
    entities: 'Account',
    type: RULE_TYPES.presentation,
    rule: {
      multi_map_id: rule.multiMapId,
      ...roles,
    },
  }
}

export const exportRules = (ruleIds) => {
  return http
    .post(
      '/rule/rules/export',
      { ids: ruleIds },
      { params: { dependency: 'True' }, responseType: 'blob' }
    )
    .then((response) => {
      downloadFile(response)
    })
}

export const importRules = (file) => {
  let formData = new FormData()
  formData.append('file', file)

  return http
    .post('/rule/rules/import', formData, {
      params: { dependency: 'True' },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      return true
    })
    .catch((error) => {
      throw (
        error?.response?.data?.error?.message ||
        'Something went wrong. Please try again later.'
      )
    })
}
